module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160770726-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5296648c5febde5bdaf3dba04d0bfc4"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"scotomalab","accessToken":"MC5YZUx2WFJFQUFDVUFpaWt5.DO-_vUwO77-9dO-_ve-_ve-_ve-_ve-_ve-_vWfvv73vv71777-9THHvv71977-977-9CApKRe-_ve-_vQPvv73vv70","fetchLinks":[],"schemas":{"project":{"Main":{"uid":{"type":"UID","config":{"label":"Project ID","placeholder":"Enter Project ID Here (ex. https://scotomalab/work/[Project ID]"}},"project_name":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Project Name","placeholder":"Enter Project Name Here"}},"project_menu_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Project Menu Image"}},"images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image"}},"caption":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Caption","placeholder":"Enter Caption Here"}}},"label":"images"}}}}},"lang":"*"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
