/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import posed from "react-pose"

import "./layout.css"

const Transition = posed.div({
  preEnter: {
    delay: 0,
    y: 0,
    opacity: 0,
  },
  enter: {
    opacity: 1,
    y: 0,
    delay: 500,
    transition: {
      duration: 1500,
      ease: "easeInOut",
    },
    applyAtStart: { display: "block" },
  },
  exit: {
    y: 0,
    opacity: 0,
    transition: {
      duration: 250,
      ease: "easeInOut",
    },
    applyAtEnd: { display: "none" },
  },
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Transition
        style={
          {
            // position: "absolute",
            // top: 0,
            // overflow: "hidden",
            // width: "100vw",
          }
        }
      >
        {children}
      </Transition>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
