import React from "react"
import posed, { PoseGroup } from "react-pose"
import styled from "styled-components"

import Menu from "./menu"

// import MenuMobile from "./menu-mobile"

import SiteWrapper from "./sitewrapper"

import Layout from "./layout"

const timeout = 300

class Transition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      store: "",
      isMobile: false,
    }
  }

  //   setTimeout(() => {
  //     this.divRef.classList.remove("hide-all")
  //     this.divRef.classList.add("show-all")
  //   }, 250)

  componentDidMount() {
    // if (window.innerWidth < 576) {
    //   this.setState({
    //     isMobile: true,
    //   })
    // }
  }

  render() {
    const { children, location } = this.props
    // const RoutesContainer = posed.div({
    //   enter: { delay: timeout, delayChildren: timeout },
    // })

    const RoutesContainer = posed.div()

    // To enable page transitions on mount / initial load,
    // use the prop `animateOnMount={true}` on `PoseGroup`.

    return (
      <div ref={el => (this.divRef = el)} className="hide-all">
        <SiteWrapper>
          <Menu />
          <PoseGroup preEnterPose="preEnter" location={location}>
            <RoutesContainer key={location.pathname}>
              <Layout {...this.props}>{children}</Layout>
            </RoutesContainer>
          </PoseGroup>
        </SiteWrapper>
      </div>
    )
  }
}

export default Transition
