import React, { useContext } from "react"
import styled from "styled-components"
import posed from "react-pose"

import { Link } from "gatsby"

import SiteContext from "../context/SiteContext"

import ScotomaLogo from "../images/scotoma.svg"
import ScotomalabLogo from "../images/scotomalab.svg"

import logoOne from "../images/logos/nike.png"
import logoTwo from "../images/logos/balenciaga.svg"
import logoThree from "../images/logos/prada.png"

import arrowdown from "../images/arrowdown.svg"

import ArrowMenuIcon from "../images/icons/Arrowdown.svg"

const preArrowDown = posed.span({
  openSubMenu: {
    rotate: 180,
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
  closedSubMenu: {
    rotate: 0,
    transition: {
      duration: 200,
      ease: "easeInOut",
    },
  },
})

const ArrowDown = styled(preArrowDown)`
  height: 10px;
  width: 10px;
  position: relative;
  top: -2px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const Scotoma = styled.div`
  position: fixed;
  width: auto;
  height: 54px;
  display: flex;
  flex-direction: row;
  left: 50%;
  transform: translate(-50%);
  top: 18px;
  z-index: 999;
  transition-duration: 0.86s;
  transition-timing-function: "cubic-bezier(0.52, 0.16, 0.24, 1)";
  pointer-events: initial;
`

const MenuDrawer = styled.div`
  position: relative;
  width: fit-content;
  height: 100%;
  z-index: 999;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
`

const preSubMenu = posed.div({
  openSubMenu: {
    delay: 100,
    delayChildren: 200,
    staggerChildren: 30,
    height: "50px",
    applyAtStart: { display: "block" },
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
  closedSubMenu: {
    delayChildren: 0,
    staggerChildren: 0,
    delay: 200,
    height: "0px",
    applyAtEnd: { display: "none" },
    transition: {
      duration: 200,
      ease: "easeInOut",
    },
  },
})

const SubMenu = styled(preSubMenu)`
  height: 0;
  display: none;
`

const preSubMenuSlider = posed.div({
  openSubMenu: {
    opacity: 1,
    applyAtStart: { display: "flex" },
    transition: {
      duration: 200,
      ease: "easeInOut",
    },
  },
  closedSubMenu: {
    opacity: 0,
    applyAtEnd: { display: "none" },
    transition: {
      duration: 200,
      ease: "easeInOut",
    },
  },
})

const SubMenuSlider = styled(preSubMenuSlider)`
  display: flex;
  flex-direction: row;
  width: 285px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 25px;

  div:last-child {
    padding-right: 50px;
  }

  @media (min-width: 576px) {
    width: 380px;
  }
`

const SubMenuSliderElem = styled.div`
  height: 30px;
  padding-left: 50px;

  img {
    height: 100%;
  }
`

const preMenuList = posed.ul({
  open: {
    delay: 0,
    delayChildren: 200,
    staggerChildren: 30,
    height: props => props.menuHeight - 45 - 30 - 18,
    applyAtStart: { display: "flex", visibility: "visible" },
    transition: {
      duration: 200,
      ease: [0.01, 0.64, 0.99, 1],
    },
  },
  closed: {
    delayChildren: 0,
    staggerChildren: 0,
    delay: 0,
    height: 0,
    applyAtEnd: { display: "none", visibility: "visible" },
    transition: {
      duration: 200,
      ease: "easeInOut",
    },
  },
})

const MenuList = styled(preMenuList)`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(25px);
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  z-index: -1;
`

const preListItem = posed.li({
  open: {
    opacity: 1,
    y: 0,
    // filter: "blur(0px)",

    applyAtStart: { display: "block" },
    transition: {
      opacity: {
        duration: 500,
        ease: "easeInOut",
      },
      y: {
        duration: 1500,
        ease: "easeOut",
      },
      filter: {
        duration: 300,
        ease: "easeOut",
      },
    },
  },
  closed: {
    opacity: 0,
    y: 0,
    applyAtEnd: { display: "none" },
    transition: {
      opacity: {
        duration: 100,
        ease: "easeInOut",
      },
      y: {
        duration: 1500,
        ease: "easeIn",
      },
      filter: {
        duration: 200,
        ease: "easeInOut",
      },
    },
  },
})

const ListItem = styled(preListItem)`
  font-family: Averta Semibold;
  letter-spacing: 0;
  font-weight: bold;
  font-size: 1.7rem;
  margin: 0 auto;
  width: fit-content;
  cursor: pointer;
  display: block;
  // text-transform: lowercase;

  a {
    display: block;
    // transform: skew(0);
    opacity: 1;
    transition-duration: 0.3s;
    text-align: center;
    color: black;
    text-decoration: none;
  }

  a span {
    display: inline-block;
  }

  a:hover {
    transition-duration: 0.3s;
    // transform: skew(-5deg, 0deg);
    opacity: 0.5;
    // letter-spacing: 1px;
  }

  @media (min-width: 576px) {
    font-size: 1.7rem;
  }
`

const Line = styled.div`
  display: block;
  height: 1px;
  width: 150px;
  background-color: black;
`

const ListItemLogo = styled(preListItem)`
  margin: 0 auto;
  width: fit-content;
  padding: 1.5rem 0;
  // cursor: pointer;

  img {
    display: block;
    margin: 0 auto;
    width: 220px;
  }
`

const Logo = styled.div`
  padding: 2px;
  margin-right: 10px;
  transition-duration: 1s;
  z-index: 998;
  display: none;

  img {
    animation-name: rotate-logo;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 100%;
    width: 56px;
    transform: translateZ(0px);
  }

  @keyframes rotate-logo {
    from {
      transform: rotateY(360deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }

  @media (min-width: 576px) {
    display: block;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(19, 15px);
  grid-template-rows: repeat(3, 15px);
  transition: 0.5s;
  transition-timing-function: "ease-in-out";
  z-index: 999;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

  :hover {
    cursor: pointer;
  }

  @media (min-width: 576px) {
    grid-template-columns: repeat(19, 18px);
    grid-template-rows: repeat(3, 18px);
  }
`

const Square = styled.div`
  transition: 0.1s;
`

const preArrowMenu = posed.div({
  open: {
    top: "100%",
    opacity: 1,
    // delay: 1000,
    transition: {
      duration: 1000,
      ease: [0.01, 0.64, 0.99, 1],
    },
  },
  closed: {
    top: "-10%",
    opacity: 0,
    transition: {
      duration: 200,
      ease: "easeInOut",
    },
  },
})

const ArrowMenu = styled(preArrowMenu)`
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  margin-top: 0px;
  transform: translateY(0);
  animation: hovereffect 1s alternate infinite;
  cursor: pointer;

  @keyframes hovereffect {
    0% {
      transform: translateY(0) translateX(-50%);
    }
    100% {
      transform: translateY(10%) translateX(-50%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  @media (min-width: 576px) {
    margin-top: 8px;
  }
`

const colorsArray = [
  "009ee2",
  "0ec2fa",
  "9bdcf9",
  "d2edfd",
  "e3007a",
  "f364a2",
  "f9b0cf",
  "fcd8e9",
  "feedf3",
  "ffea01",
  "fff164",
  "fff7ae",
  "fffcdb",
  "fffdee",
  "ebebeb",
  "dadada",
  "b2b2b4",
  "88888a",
  "58585a",
  "0f238b",
  "6154a4",
  "a197c9",
  "e4e6f5",
  "e4001b",
  "f86341",
  "fcac8b",
  "fed8c4",
  "feebdc",
  "009038",
  "46b669",
  "a9d5aa",
  "d7ebd6",
  "ecf4eb",
  "ececee",
  "d4d9da",
  "a6b0b2",
  "798681",
  "4c5d57",
  "1b2f28",
  "6b574c",
  "ae978f",
  "e9e3e0",
  "d85e42",
  "964e3d",
  "961316",
  "97084d",
  "99830a",
  "00602d",
  "006473",
  "0c245b",
  "eba585",
  "f8a700",
  "e30059",
  "a4007c",
  "b6c700",
  "00989e",
  "0074bd",
]

let hasTriggered = false

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false,
      isSubMenuOpen: false,
    }

    this.menuHeight = 0

    this.squareRefs = []
  }

  componentDidUpdate() {
    window.addEventListener("scroll", () => this.mouseLeaveScotoma())
  }

  componentDidMount() {
    this.menuHeight = window.innerHeight

    window.addEventListener(
      "scroll",
      () =>
        // this.setState({
        //   menuHeight: window.innerHeight,
        // })
        (this.menuHeight = window.innerHeight)
    )

    window.addEventListener("scroll", () => this.mouseLeaveScotoma())
  }

  renderGrid = colorsArray => {
    return colorsArray.map((item, index) => {
      return (
        <Square
          ref={el => (this.squareRefs[index] = el)}
          onMouseEnter={() => this.mouseEnterSquare(index)}
          onMouseLeave={() => this.mouseLeaveSquare(index)}
          style={{ backgroundColor: `#${item}` }}
        />
      )
    })
  }

  mouseEnterSquare = index => {
    //Squares Transform
    this.squareRefs.forEach(item => {
      item.style.opacity = 1
      item.style.transform = "scale(1)"
      this.squareRefs[index].style.zIndex = 998
    })
    //Square Transform
    this.squareRefs[index].style.zIndex = 999
    this.squareRefs[index].style.opacity = 0.8
    this.squareRefs[index].style.transform = "scale(1)"

    // this.context.changeColor(index)

    //Set BackgroundColor

    document.querySelector(
      "body"
    ).style.backgroundColor = `#${colorsArray[index]}`
  }

  mouseEnterScotoma = index => {
    this.setState({
      isMenuOpen: true,
    })
    //Menu Transform
    // this.menuRef.style.maxHeight = "999px";
    this.menuRef.style.transform = "scale(1) translateY(0%)"

    //Logo Transform
    // this.logoRef.style.transform = "translateX(70px)";
  }

  toggleScotoma = index => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
    }))
  }

  mouseLeaveSquare = index => {
    //Squares Transform
    this.squareRefs.forEach(item => {
      item.style.opacity = 1
      item.style.transform = "scale(1)"
      this.squareRefs[index].style.zIndex = 998
    })
    //Lift Color To Parent
    // this.props.color(colorsArray[index]);
  }

  mouseLeaveScotoma = index => {
    //Menu Transform
    // this.menuRef.style.maxHeight = 0;
    // this.menuRef.style.transform = "scale(1) translateY(0)"

    if (!hasTriggered) {
      this.setState({
        isMenuOpen: false,
      })
    }

    hasTriggered = true

    setTimeout(() => {
      hasTriggered = false
    }, 1000)

    // //Logo Transform
    // this.logoRef.style.transform = "translateX(0)"
  }

  toggleSubMenu = () => {
    // this.setState(prevState => ({
    //   isSubMenuOpen: !prevState.isSubMenuOpen,
    // }))
  }

  render() {
    return (
      <Scotoma
        onClick={() => this.toggleScotoma()}
        // onMouseEnter={() => this.mouseEnterScotoma()}
        // onMouseLeave={() => this.mouseLeaveScotoma()}
      >
        <Logo ref={el => (this.logoRef = el)} className="rotate-logo">
          <img src={ScotomaLogo} />
        </Logo>
        <MenuDrawer ref={el => (this.menuRef = el)}>
          <Grid ref={el => (this.gridRef = el)}>
            {this.renderGrid(colorsArray)}
          </Grid>
          <ArrowMenu pose={this.state.isMenuOpen ? "closed" : "open"}>
            <img src={ArrowMenuIcon} />
          </ArrowMenu>
          <MenuList
            pose={this.state.isMenuOpen ? "open" : "closed"}
            menuHeight={this.menuHeight}
            className="menu-list"
          >
            <ListItem>
              <span>
                <Link activeClassName="active-link" to="/">
                  Explore
                </Link>
              </span>
            </ListItem>
            <ListItem>
              <span>
                <Link activeClassName="active-link" to="/lab">
                  Lab
                </Link>
              </span>
            </ListItem>
            {/* <ListItem onClick={this.toggleSubMenu}>
              <Link to="/work/all">
                WORK
                <ArrowDown
                  pose={
                    this.state.isSubMenuOpen ? "openSubMenu" : "closedSubMenu"
                  }
                >
                  <img src={arrowdown} />
                </ArrowDown>
              </Link>

              <SubMenu
                pose={
                  this.state.isSubMenuOpen ? "openSubMenu" : "closedSubMenu"
                }
              >
                <SubMenuSlider className={"noscrollbar"}>
                  <SubMenuSliderElem>
                    <img src={logoOne} />
                  </SubMenuSliderElem>
                  <SubMenuSliderElem>
                    <img src={logoTwo} />
                  </SubMenuSliderElem>
                  <SubMenuSliderElem>
                    <img src={logoThree} />
                  </SubMenuSliderElem>
                </SubMenuSlider>
              </SubMenu>
            </ListItem> */}
            
            <ListItem>
              <Line />
            </ListItem>
            <ListItemLogo>
              {/* <Link to="/"> */}
              <img src={ScotomalabLogo} />
              {/* </Link> */}
            </ListItemLogo>
          </MenuList>
        </MenuDrawer>
      </Scotoma>
    )
  }
}

Menu.contextType = SiteContext

export default Menu
